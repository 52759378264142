import React, { Component } from "react"
import Account from "./../account"
import { useNavigate, Link, useParams } from "react-router-dom"
import Loader from "../../components/UI/Loader"
import ErrorAlert from "../../components/errorAlert"
import axios from "axios"
import { getErrorMessage } from "../../helpers"
import { checkValidationErrors } from "./form-validation"
import { Button } from "../../components/UI/Button"
import { TextInput } from "../../components/UI/TextInput"
import TermsOfUse from '../termsOfUse'
import PrivacyPolicy from '../privacyPolicy'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import {withTranslation} from "react-i18next";
import {LanguageChanger} from "../../components/languageChanger";
import LanguagesPopup from "../../components/UI/LanguagesPopup";
import i18n from "i18next";

export const withNavigation = (Component: Component) => {
  return (props) => (
    <Component {...props} params={useParams()} navigate={useNavigate()} />
  )
}

class Signup extends Component {
  constructor() {
    super()
    this.phoneNumberInputRef = React.createRef();
    this.emailInputRef = React.createRef();
    this.state = {
      checked: false,
      fields: {},
      errors: {},
      apiErrorMsg: "",
      showError: false,
      registrationLanguagePopup: true,
      termsAndUsePopup: false,
      privacyPolicyPopup: false,
      termsAndPoliceChecked: true,
    }
  }
    handleToggleRegistrationLanguagePopup = () => {
        this.setState({ registrationLanguagePopup: !this.state.registrationLanguagePopup })
    }
    handleShowTermsAndUsePopup = () => {
        this.setState({ termsAndUsePopup: true })
    }
    handleShowPrivacyPolicyPopup = () => {
        this.setState({ privacyPolicyPopup: true })
    }
  handleCheckboxChange =() => {
    this.setState({
      checked: !this.state.checked
    })
  }
  getSignupFormValues() {
    const fromBackValue = JSON.parse(localStorage.getItem("forBackValues")) ||
        { selectedRegisterType: "Email",
          phoneCode: '374'};
    return fromBackValue;
  }
  contactSubmit(e) {
    this.setState({ loading: true })
    this.setState({errors: {}})
    this.setState({apiErrorMsg: ''})
    e.preventDefault()
    const errors = checkValidationErrors(this.state.fields, this.props.t)
    if (errors) {
      this.setState({ loading: false, errors })
    } else {
      this.signUp()
    }
  }

  componentDidMount() {
    const formData = this.getSignupFormValues();
    this.setState({ fields: formData });
  }

  componentDidUpdate(prevProps, prevState) {
    const { fields } = this.state;
    if (fields['phoneHighlight'] && fields['phoneHighlight'] !== prevState.fields['phoneHighlight']) {
      if (this.phoneNumberInputRef.current) {
        this.phoneNumberInputRef.current.focus();
      }
    }
    if (fields['emailHighlight'] && fields['emailHighlight'] !== prevState.fields['emailHighlight']) {
      if (this.emailInputRef.current) {
        this.emailInputRef.current.focus();
      }
    }
  }
  handleChange(field, e, el) {
    let fields = this.state.fields
    if(field === "email") e.target.value = e.target.value.trim()
    if(field === 'phoneCode') {
      fields["phoneCode"] = el.dialCode;
    } else {
      fields[field] = e.target.value
    }
    this.setState({ fields })
  }


  signUp = () => {
    const { fields } = this.state
    const lng = i18n.language === 'en' ? 2 : 1
    const highlight = fields["selectedRegisterType"] === "Email" ?
        'emailHighlight' :
        'phoneHighlight'
    const body = {
      firstName: fields["firstName"],
      lastName: fields["lastName"],
      specialityId: fields["speciality"],
      registrationMethod: fields["selectedRegisterType"],
      password: fields["password"],
      phoneNumberCountryCode: "",
      phoneNumber: "",
      email: "",
    }
    if(fields["selectedRegisterType"] === 'Email'){
      body.email = fields["email"] || ""
    }
    if(fields["selectedRegisterType"] === 'Phone'){
      body.phoneNumberCountryCode = fields["phoneCode"] || ""
      body.phoneNumber = fields["phoneNumber"] || ""
    }
    const url = fields["selectedRegisterType"] === "Email" ?
        `${process.env.REACT_APP_IDENTITY_URL}/api/User/EmailAvailable/${fields["email"]}` :
        `${process.env.REACT_APP_IDENTITY_URL}/api/User/PhoneAvailable/${fields["phoneCode"]}/${body.phoneNumber}`
    axios
      .get(
        url
      )
      .then((res) => {
        const errorState = {
          loading: false,
          showError: true,
        }
        if (res.data.accepted) {
          if (res.data.data[0]) {
            const sendUrl = fields["selectedRegisterType"] === "Email" ?
                `${process.env.REACT_APP_IDENTITY_URL}/api/User/SendConfirmationEmail/${fields["email"]}?languageId=${lng}` :
                `${process.env.REACT_APP_IDENTITY_URL}/api/User/SendConfirmationSMS/${fields['phoneCode']}/${body.phoneNumber}?languageId=${lng}`
                  localStorage.setItem("signUpForm", JSON.stringify(body));
            axios.put(
                sendUrl
            )
            .then((resp) => {
                if(resp.data.accepted) {
                  fields[`${highlight}`] = true
                  localStorage.setItem("forBackValues", JSON.stringify(fields));
                  this.props.navigate("/confirm-account")
                }
            })
            return
          } else {
            errorState.apiErrorMsg =
                fields["selectedRegisterType"] === "Email" ?
                    "Այս էլեկտրոնային հասցեով օգտատեր արդեն գրանցվել է։" :
                    "Հեռախոսահամարն արդեն գրանցված է։"
            this.setState(errorState)
          }
        } else {
          errorState.apiErrorMsg = getErrorMessage(res.data.errorMessages)
          this.setState(errorState)
        }
      })
      .catch(err=>{
        const errorState = {
          loading: false,
          showError: true,
        }
        errorState.apiErrorMsg = getErrorMessage(err)
        this.setState(errorState)
      })
  }

  render() {
    const { errors, fields, loading, showError, apiErrorMsg, checked } = this.state
    const { handleChange, contactSubmit, setState, handleCheckboxChange } = this
    const { t } = this.props
    return (
      <Account onlyBG={true}>
        {/*<div className='language-container'><LanguageChanger /></div>*/}
        <div className="account-content">
            <h2 className="page-title">{t('signUp')}</h2>
            <div
            className={'switchButtonContainer'}>
              <Button
                  value="Email"
                  action={handleChange.bind(this, "selectedRegisterType")}
                  className={fields["selectedRegisterType"] === 'Email' ? 'switchButtonActive' : 'switchButton'}
                  text={t('signInEmail')}
              />
              <Button
                  value='Phone'
                  action={handleChange.bind(this, "selectedRegisterType")}
                  className={fields["selectedRegisterType"] === 'Phone' ? 'switchButtonActive' : 'switchButton'}
                  text={t('signInPhoneNumber')}
              />
            </div>
            <form className="account-form" onSubmit={contactSubmit.bind(this)}>
              <TextInput
                hasError={errors["firstName"]}
                placeholder={`${t('firstName')} *`}
                onChange={handleChange.bind(this, "firstName")}
                value={fields["firstName"] || ""}
              />
              <TextInput
                hasError={errors["lastName"]}
                placeholder={`${t('lastName')} *`}
                onChange={handleChange.bind(this, "lastName")}
                value={fields["lastName"] || ""}
              />
              <div
              className={'speciality-box'}
              style={{
                marginTop: 10,
              }}>
                <label
                className={'select'}>
                  <input
                      type="radio"
                      value="1"
                      checked={fields['speciality'] == '1'}
                      onChange={handleChange.bind(this, "speciality")}
                  />
                  <span>{t('userType.teacher')}</span>
                </label>
                <label
                    className={'select'}>
                  <input
                      type="radio"
                      value="3"
                      checked={fields['speciality'] == '3'}
                      onChange={handleChange.bind(this, "speciality")}
                  />
                  <span>{t('userType.student')}</span>
                </label>
                <label
                    className={'select'}>
                  <input
                      type="radio"
                      value="4"
                      checked={fields['speciality'] == '4'}
                      onChange={handleChange.bind(this, "speciality")}
                  />
                  <span>{t('userType.other')}</span>
                </label>
              </div>
              <div
                style={{
                alignSelf: 'flex-end'
              }}>{errors["speciality"] && <span className={'error-text'}>* {errors["speciality"]}</span>}</div>
                {fields["selectedRegisterType"] === 'Email' ?
                  <div
                      className={'email-box'}>
                   <input
                  className={errors['email'] ||
                  apiErrorMsg === 'Այս էլեկտրոնային հասցեով օգտատեր արդեն գրանցվել է։' ?
                      "emailError" :
                      fields['emailHighlight'] ?
                      "emailInputHighlighted" :
                      "emailInput"}
                  ref={this.emailInputRef}
                  hasError={errors["email"] ||
                      apiErrorMsg === 'Այս էլեկտրոնային հասցեով օգտատեր արդեն գրանցվել է։' &&
                      t('errors.usedEmail')}
                  placeholder={`${t("signInEmailPlaceholder")} *`}
                  onChange={handleChange.bind(this, "email")}
                  value={fields["email"] || ""}
              />
                    {errors["email"] && <span className={'error-text'}>* {errors["email"]}</span>}
                    {apiErrorMsg === 'Այս էլեկտրոնային հասցեով օգտատեր արդեն գրանցվել է։'
                        && <span className={'error-text'}>{t('errors.usedEmail')} *</span>}
                  </div>
                      :
                  <div
                  className={'phone-box'}>
                    <PhoneInput
                        inputProps={{
                          disabled: true,
                          style: { pointerEvents: 'none'}
                        }}
                        countryCodeEditable={false}
                        containerClass='phoneContainer'
                        inputClass={errors["phoneNumber"] ||
                        apiErrorMsg === 'Հեռախոսահամարն արդեն գրանցված է։' ?
                            "phoneInputError" :
                            fields['phoneHighlight'] ?
                                "phoneInputHighlighted" :
                                "phoneInput"}
                        buttonClass='flagButton'
                        country={'am'}
                        value=""
                        onChange={handleChange.bind(this, "phoneCode")}
                    />
                    {apiErrorMsg === 'Հեռախոսահամարն արդեն գրանցված է։' ?
                        <span className={'error-text'}>{t('errors.usedPhone')} *</span> :
                        errors["phoneNumber"] ?
                        <span className={'error-text'}>{errors["phoneNumber"]} *</span> :
                    null}
                    <input
                        onInput={(e) => e.target.value =
                            fields.phoneCode == '374' ? e.target.value.slice(0, 8) :
                                e.target.value.slice(0, 15)}
                        ref={this.phoneNumberInputRef}
                        value={fields['phoneNumber']}
                        type="number"
                        placeholder={fields['phoneCode'] == '374' ?
                            `${t('signInPhoneNumberPlaceholder')} *` :
                            `${t('phoneNumber')} *`}
                        onChange={handleChange.bind(this, "phoneNumber")}
                        className={errors["phoneNumber"] || apiErrorMsg === 'Հեռախոսահամարն արդեն գրանցված է։'
                            ? 'phoneNumberInputError':'phoneNumberInput'}/>
                  </div>}
              <TextInput
                hasError={errors["password"]}
                placeholder={`${t('password')} *`}
                type="password"
                onChange={handleChange.bind(this, "password")}
                value={fields["password"] || ""}
              />
              <TextInput
                hasError={errors["cPassword"]}
                placeholder={`${t('repeatPassword')} *`}
                type="password"
                onChange={handleChange.bind(this, "cPassword")}
                value={fields["cPassword"] || ""}
              />
                <div
                    style={{
                        display: 'flex',
                        gap: '10px',
                        marginBottom: '20px',
                    }}
                    className={`input-wrapper`}
                >
                    <div>
                      <input
                          className='square-checkbox'
                          type="checkbox"
                          checked={checked}
                          onChange={handleCheckboxChange}
                      />
                    </div>
                    <div
                        style={{
                          letterSpacing: '1px',
                          textAlign: 'start',
                          fontSize: '16px',
                          fontWeight: '300'
                        }}
                    >
                        <span style={{ color: '#fff' }}>
                          {t('accept')}
                        </span>
                        <span
                            className="terms-police-title"
                            style={{ margin: '0rem 0.2rem' }}
                            onClick={this.handleShowPrivacyPolicyPopup}
                        >
                            {t('terms')}
                        </span>
                        <span style={{ color: '#fff' }}>{t('and')}</span>
                        <span
                            className="terms-police-title"
                            style={{ margin: '0rem 0.2rem' }}
                            onClick={this.handleShowTermsAndUsePopup}
                        >
                          {t('conditions')}
                        </span>
                    </div>
                </div>
              <Button
                  disabled={!checked}
                  className={!checked ? 'disabled-button' : "account-submit"}
                  text={t('register')}
                  action={contactSubmit.bind(this)} />
            </form>
            {loading && <Loader />}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <span style={{ color: "#fff" }}>{t('alreadyRegistered')}</span>
              <Link className="reg" to="/login">
                {t('logIn')}
              </Link>
            </div>
          </div>
        {
          this.state.registrationLanguagePopup && (
              <>
                <LanguagesPopup onClose={this.handleToggleRegistrationLanguagePopup}/>
              </>
            )
        }
        {this.state.termsAndUsePopup ? (
            <TermsOfUse
                handleTerms={() => {
                    this.setState({
                        termsAndUsePopup: !this.state.termsAndUsePopup,
                    })
                }}
                handlePolicy={() => {
                  this.setState({
                    privacyPolicyPopup:
                        !this.state.privacyPolicyPopup,
                  })
                }}
            />
        ) : null}
        {this.state.privacyPolicyPopup ? (
            <PrivacyPolicy
                handlePolicy={() => {
                    this.setState({
                        privacyPolicyPopup:
                            !this.state.privacyPolicyPopup,
                    })
                }}
            />
        ) : null}
      </Account>
    )
  }
}

export default withTranslation()(withNavigation(Signup))
