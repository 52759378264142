import React, {useState} from "react";
import i18n from "i18next";
import './style.css'
import ArmFlag from '../../assets/imgs/icons/arm-flag.svg'
import UkFlag from '../../assets/imgs/icons/uk-flag.svg'
import ArrowImg from '../../assets/imgs/icons/language-arrow.svg'
import axios from "axios";
import {userInfoSettingsURI} from "../../shared/mockData";
import {changeLanguage} from "../../utils/utils";

export const LanguageChanger = () => {
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const lng = i18n.language

    const toggleDropdown = () => {
        setDropdownOpen(!isDropdownOpen);
    };

    const switchLanguage = (language) => {
        changeLanguage(language);
        setDropdownOpen(false);
    };
    return (
        <div className="language-selector">
            <div className="dropdown-button" onClick={toggleDropdown}>
                <img style={{width: '25px', height: '25px'}} src={lng === 'en' ? UkFlag : ArmFlag}/>
                <img style={{width: '13px', height: '7px', marginLeft: '5px'}} src={ArrowImg}/>
            </div>
            <div className={`dropdown-menu ${isDropdownOpen ? 'open' : ''}`}>
                <div className="dropdown-item" onClick={() => switchLanguage('hy')}>
                    <img src={ArmFlag}/>
                </div>
                <div className="dropdown-item" onClick={() => switchLanguage('en')}>
                    <img src={UkFlag}/>
                </div>
            </div>
        </div>
    )
}
