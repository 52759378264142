import React, {useEffect} from "react";
import "@splidejs/splide/dist/css/splide.min.css";
import { connect, useSelector } from "react-redux";
import Plus from "../UI/Plus";
import ReturnNavigation from "../UI/ReturnNavigation";
import Minus from "../UI/Minus";
import { setMapZoomIn, setMapZoomOut } from "../../store/actions/zoom";
import ArticlePopup from "../articlePopup";
import Compass from "../UI/Compass";
import {
  getMapBounceData,
  getMapZoomData,
  getResetCompass,
  getGalleryFullScreen,
  getGalleryIndex,
  getIsGalleryMode,
  getIsReadMoreState,
} from "../../store/selectors";
import {
  setResetCompass,
  setCompassNewAngle,
  setCompassRotate,
  setCompassCoordinates,
} from "../../store/actions/compassStateAction";

const queryParams = new URLSearchParams(window.location.search);

const RightSide = (props) => {
  const { setMapZoomIn,
    setMapZoomOut,
    stopPinAnimation,
    globalLoading,
    isReadMoreState,
    isGalleryMode,
  } = props;


  return (
    <>
      <div className="main__right right-side no-select">
        <div className="zoom-btns">
          <div className="main__tab"
               id="mobile_mode_main_tab_zoom_minus"
               data-is-loading={globalLoading}
               style={{'--loader-color':'#ececec '}}>
            <button className="zoom-btn" type="button" onClick={setMapZoomOut}>
              <span className="main__tab-icon icon-wrapper">
                <Minus />
              </span>
            </button>
          </div>
          <div className="main__tab" id="mobile_mode_main_tab_zoom_plus"
               data-is-loading={globalLoading}
               style={{'--loader-color':'#ececec '}}>
            <button className="zoom-btn" type="button" onClick={setMapZoomIn}>
              <span className="main__tab-icon icon-wrapper">
                <Plus />
              </span>
            </button>
          </div>
        </div>
        {/* <div className="empty-line" style={{height:'5vh'}}></div> */}
        {!queryParams.get("articleReadMode") && (
          <>
            <div
              className="main__tab compass-box circle-load"
              id="mobile_compass_box"
              style={{ backgroundColor: "#0000",'--loader-color':'#ececec ' }}
              data-is-loading={globalLoading}
            >
              <Compass globalLoading={globalLoading} mapState={props.mapState} zoom={props.mapZoom} />
            </div>
            <div
              className="main__tab return-nav-btn-box mobile_mode_main_tab"
              id="mobile_mode_main_tab_return"
              data-is-loading={globalLoading}
              style={{'--loader-color':'#ececec '}}
            >
              <button
                className="return-nav-btn"
                type="button"
                onClick={() => {
                  props.setResetCompass(!props.getResetCompass);
                  props.mapState.fitBounds(props.mapBounce, {
                    pitch: 0,
                    maxZoom: 7,
                    padding: { top: 40, bottom: 200, left: 40, right: 40 },
                    duration: 1000,
                  });
                  props.setCompassNewAngle(0);
                  props.setCompassRotate(0);
                }}
              >
                <span className="main__tab-icon icon-wrapper return_navigation_button">
                  <ReturnNavigation />
                </span>
              </button>
            </div>
          </>
        )}
      </div>
      {!queryParams.get("articleReadMode") && <ArticlePopup
          isGallery={isGalleryMode}
          showReadMore={isReadMoreState}
          map={props.mapState}
          stopPinAnimation={stopPinAnimation}/>}
    </>
  );
};

const mapStateTopProps = (state) => ({
  mapZoom: getMapZoomData(state),
  mapBounce: getMapBounceData(state),
  getResetCompass: getResetCompass(state),
  isReadMoreState: getIsReadMoreState(state),
  isGalleryMode: getIsGalleryMode(state),
  galleryIndex: getGalleryIndex(state),
  galleryFullScreen: getGalleryFullScreen(state),
});

const mapDispatchToProps = {
  setMapZoomIn,
  setMapZoomOut,
  setResetCompass,
  setCompassNewAngle,
  setCompassRotate,
  setCompassCoordinates,
};

export default connect(mapStateTopProps, mapDispatchToProps)(RightSide);
